import { Component, Mixins } from "vue-property-decorator";

import ReferralProgramMixin from "../../common/mixins/referral-program.mixin";

import ReferralProfit from "../../common/components/ReferralProfit/ReferralProfit.vue";
import ReferralInviteUrl from "../../common/components/ReferralInviteUrl/ReferralInviteUrl.vue";

@Component({
  components: {
    ReferralProfit,
    ReferralInviteUrl,
  },
})
export default class DesktopReferralProgram extends Mixins(
  ReferralProgramMixin
) {
  private mounted() {
    this.mountedHook();
  }
}
