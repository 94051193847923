import { render, staticRenderFns } from "./DesktopReferralProgram.vue?vue&type=template&id=8e13441e&scoped=true&"
import script from "./DesktopReferralProgram.ts?vue&type=script&lang=ts&"
export * from "./DesktopReferralProgram.ts?vue&type=script&lang=ts&"
import style0 from "./DesktopReferralProgram.scss?vue&type=style&index=0&id=8e13441e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e13441e",
  null
  
)

export default component.exports