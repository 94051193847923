var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DesktopScreen',{staticClass:"referral-program"},[(_vm.canInviteReferralsGetter)?[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('ReferralInviteUrl',{staticClass:"flex-shrink-1 flex-grow-1",style:({
          width: '0',
        })}),_c('ReferralProfit',{staticClass:"flex-shrink-0"})],1),_c('v-data-table',{staticClass:"referral-program-table mt-6",attrs:{"mobile-breakpoint":100,"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"footer-props":{
        itemsPerPageOptions: [15, 30, 45],
      }},scopedSlots:_vm._u([{key:`header`,fn:function(){return [_c('thead',{staticClass:"v-data-table-header data-table-custom-header referral-program-table__header"},[_c('tr',_vm._l((_vm.tableHead),function(item,itemIndex){return _c('th',{key:itemIndex,class:{
                'text-center': item.align === 'center',
                'text-left': item.align === 'start',
              },style:({ width: item.width }),attrs:{"rowspan":item.rowSpan,"colspan":item.children?.length}},[_c('span',{class:{
                  'd-block rounded referral-program-table__header-top':
                    !!item.children,
                }},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0),_c('tr',[_vm._l((_vm.tableHead),function(item,itemIndex){return [(item.children)?_vm._l((item.children),function(child,childIndex){return _c('th',{key:`${itemIndex}_${childIndex}`,class:{
                    'text-center': item.align === 'center',
                    'text-left': item.align === 'start',
                  }},[_vm._v(" "+_vm._s(child.text)+" ")])}):_vm._e()]})],2),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_c('td',{staticClass:"pa-0",staticStyle:{"height":"auto"},attrs:{"colspan":_vm.headers.length}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"black","height":"3"}})],1)])])]},proxy:true},{key:`item.referralId`,fn:function({ item: { referralId } }){return [_c('b',{staticClass:"text-subtitle-1 font-weight-bold blue-grey--text text--darken-4"},[_vm._v(" "+_vm._s(referralId)+" ")])]}}],null,true)})]:_c('LockedPageBanner',{attrs:{"page-name":"referral-program"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }